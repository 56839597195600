import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../../layout";
import { BlogEntry, DesktopRightImage, PhoneImage, IPhone, img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 className="px-2 sm:text-center">Trailguide news & blog</h1>
    <p className="px-2 sm:text-center">
  All about outdoor sports, tourism, digitalization and the Trailguide app.
    </p>
    <br />
    <BlogEntry mdxType="BlogEntry">
      <h3>{`Trailguide Launches Premium Subscription`}</h3>
      <br />
      <ByLine author="Bjørn Jarle Kvande" date="2024-05-18" mdxType="ByLine" />
      <br />
      <p>{`Since 2013, Trailguide has been free to use and ad-free. To further develop and
improve the service, we are now launching Trailguide Premium – a monthly subscription
for 50 NOK. The app will remain free and ad-free, but we are introducing new features
for premium subscribers. Read more about why we are doing this and how you can support
us here.`}</p>
      <p><a parentName="p" {...{
          "href": "/news/trailguide-premium"
        }}>{`Read more...`}</a></p>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <h3>{`Trailguide 2024 Spring Edition`}</h3>
      <h4>{`An overview of new features for 2024`}</h4>
      <DesktopRightImage className="hidden sm:block w-72" src="news/tg_2023_spring_edition.jpg" href="trailguide-2024-spring-edition" mdxType="DesktopRightImage" />
      <br />
      <ByLine author="Bjørn Jarle Kvande" date="2024-05-17" mdxType="ByLine" />
      <br />
      <p>{`It is that time of year again when we release new features we have been working on
during winter and spring. Check out some of the new features and see how it can
make your biking even more enjoyable this season.`}</p>
      <PhoneImage src="/news/tg_2023_spring_edition.jpg" href="trailguide_2024_spring_edition" mdxType="PhoneImage" />
      <p><a parentName="p" {...{
          "href": "/news/trailguide_2024_spring_edition"
        }}>{`Read more...`}</a></p>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage className="hidden sm:block w-72" src="news/locusmap_routeplanner.jpg" href="routeplanner" mdxType="DesktopRightImage" />
      <h3>{`Plan your route, export .gpx files`}</h3>
      <br />
      <ByLine author="Peter Seidl" date="2024-02-09" mdxType="ByLine" />
      <br />
      <p>{`Just a few clicks to plan the whole tour, know the distance and climb.
Export a .gpx file to navigate on your GPS device, or upload it to Trailguide.`}</p>
      <PhoneImage src="news/locusmap_routeplanner.jpg" href="routeplanner" mdxType="PhoneImage" />
      <p><a parentName="p" {...{
          "href": "/news/routeplanner"
        }}>{`Read more...`}</a></p>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage className="hidden sm:block w-72" src="news/winter-morten-trail.jpg" href="winter-trail-biking" mdxType="DesktopRightImage" />
      <h3>{`Embrace the chill`}</h3>
      <br />
      <ByLine author="Morten Trædal" date="2023-12-07" mdxType="ByLine" />
      <br />
      <p>{`We at Trailguide love any kind of bike riding (not really, actually we only
like mountain biking) and we realised we were lacking a category for riding on
snow.`}</p>
      <PhoneImage src="news/winter-morten-trail.jpg" href="winter-trail-biking" mdxType="PhoneImage" />
      <p><a parentName="p" {...{
          "href": "/news/winter-trail-biking"
        }}>{`Read more...`}</a></p>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <Link to="/news/why-not-native-app" mdxType="Link">
  <IPhone alt="Trailguide web app" src={img("/website/trailguide-iphone-cycleroutes.jpeg")} className="float-right hidden sm:block w-36 mt-4 ml-12" mdxType="IPhone" />
      </Link>
      <h3>{`Why don't you make Trailguide as an app?`}</h3>
      <br />
      <ByLine author="Bjørn Jarle Kvande" date="2023-08-15" mdxType="ByLine" />
      <br />
      <p>{`We often get the question "why don't you make Trailguide as an app?" from users. There are
many reasons for this and in this short blog post I try to list some of the reasons why
we make Trailguide as a web app, and one reasons why we should possible have made
it a native app.`}</p>
      <p><a parentName="p" {...{
          "href": "/news/why-not-native-app"
        }}>{`Read more...`}</a></p>
      <Link to="/news/why-not-native-app" mdxType="Link">
  <IPhone alt="Trailguide web app" src={img("/website/trailguide-iphone-cycleroutes.jpeg")} className="block sm:hidden w-3/4 mx-auto mt-4" mdxType="IPhone" />
      </Link>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <h3>{`Trailguide changelog`}</h3>
      <h4>{`An attempt to keep track of significant changes in the Trailguide app`}</h4>
      <DesktopRightImage className="hidden sm:block w-72" src="news/changelog.png" href="trailguide_changelog" mdxType="DesktopRightImage" />
      <br />
      <ByLine author="Bjørn Jarle Kvande" mdxType="ByLine" />
      <br />
      <p>{`There are literally 100s of changes and commits to the codebase every week.
Some are bug fixes, some are user interface tweaks, some are new features, some are code
quality improvements.`}</p>
      <PhoneImage src="/news/changelog.png" href="trailguide_changelog" mdxType="PhoneImage" />
      <p><a parentName="p" {...{
          "href": "/news/trailguide_changelog"
        }}>{`Read more...`}</a></p>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <h3>{`Trailguide 2023 Spring Edition`}</h3>
      <h4>{`An overview of what is possible with the Trailguide app`}</h4>
      <DesktopRightImage className="hidden sm:block w-72" src="news/tg_2023_spring_edition.jpg" href="trailguide_2023_spring_edition" mdxType="DesktopRightImage" />
      <br />
      <ByLine author="Bjørn Jarle Kvande" date="2023-05-18" mdxType="ByLine" />
      <br />
      <p>{`Trailguide is under constant development and we add new possibilities all the time,
but we are not very good at telling you about them. Check out some of the features available
and see how it can help you to find great trails to enjoy this summer.`}</p>
      <PhoneImage src="/news/tg_2023_spring_edition.jpg" href="trailguide_2023_spring_edition" mdxType="PhoneImage" />
      <p><a parentName="p" {...{
          "href": "/news/trailguide_2023_spring_edition"
        }}>{`Read more...`}</a></p>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage className="hidden sm:block w-40 shadow-md rounded" src="/website/interface/iphone_new_interface.jpeg" href="/coding/new_user_interface" mdxType="DesktopRightImage" />
      <h3>{`New user interface for Trailguide`}</h3>
      <h4>{`An overview of the latest changes and improvements`}</h4>
      <br />
      <ByLine author="Bjørn Jarle Kvande" date="2023-04-24" mdxType="ByLine" />
      <br />
      <p>{`The user interface of Trailguide has evolved a lot over the years. Little by little it
has changed as we learn more and add more features. I think it has improved the ease
and efficiency of use and at the same time making it look a lot better. See what changes
we have made this spring.`}</p>
      <PhoneImage src="/website/interface/iphone_new_interface.jpeg" href="/coding/new_user_interface" mdxType="PhoneImage" />
      <p><a parentName="p" {...{
          "href": "/coding/new_user_interface"
        }}>{`Read more...`}</a></p>
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/coding/programmerspet.jpg" href="/coding" mdxType="DesktopRightImage" />
      <h3>{`Developers corner`}</h3>
      <h4>{`See how the sausage is made`}</h4>
      <br />
      <ByLine author="Bjørn Jarle Kvande" date="2023-02-02" mdxType="ByLine" />
      <br />
      <p>{`Thoughts and ramblings from underneath a pile of 200.000 lines of JavaScript code.`}</p>
      <PhoneImage src="/coding/programmerspet.jpg" href="/coding" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/solutions/scan_code_nesbyen.jpg" href="/news/digitalization" mdxType="DesktopRightImage" />
      <h3>{`Digitalization`}</h3>
      <h4>{`What does digitalization mean for tourism?`}</h4>
      <ByLine author="Peter Seidl" mdxType="ByLine" />
      <br />
      <p>{`Digitalization is often misunderstood. Poor implementation can leave the
customers annoyed and frustrated. Find out how to use technology to enhance
your customers experience, and to optimize your own processes.`}</p>
      <p><a parentName="p" {...{
          "href": "/news/digitalization"
        }}>{`Read more...`}</a></p>
      <PhoneImage src="/solutions/scan_code_nesbyen.jpg" href="/news/digitalization" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/_DSC5668.jpeg" href="/news/preserveourtrails" mdxType="DesktopRightImage" />
      <h3>{`Preserve our trails`}</h3>
      <h4>{`How to find trails in good conditions, and to help preserve them`}</h4>
      <ByLine author="Peter Seidl" mdxType="ByLine" />
      <br />
      <p>{`A new study shows the effects of mountain biking on trails. With Trailguide
you can find trails in good conditions and help to keep them in a good shape.`}</p>
      <p><a parentName="p" {...{
          "href": "/news/preserveourtrails"
        }}>{`Read more...`}</a></p>
      <PhoneImage src="/news/_DSC5668.jpeg" href="/news/preserveourtrails" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/tjome/verdensende.png" href="/news/followmetjome" mdxType="DesktopRightImage" />
      <h3>{`Tjøme trail & code hackathon`}</h3>
      <h4>{`Developing and field testing new functions for Trailguide`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2021-05-21" mdxType="ByLine" />
      <br />
      <p>{`Last weekend we headed off to the islands of Tjøme and Nøtterøy where they
have a really dense network of trails to find out how we could improve
trailguide to help guide you through such a trail network.`}</p>
      <p><a parentName="p" {...{
          "href": "/news/followmetjome"
        }}>{`Read more about how it went here...`}</a></p>
      <PhoneImage src="/news/tjome/verdensende.png" href="/news/followmetjome" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/screenshot_new.png" href="/news/trailguide2021" mdxType="DesktopRightImage" />
      <h3>{`New interface & functions`}</h3>
      <h4>{`The next evolutionary step`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2021-05-12" mdxType="ByLine" />
      <br />
      <p>{`The new version of Trailguide comes with new functions, better ergonomics and an easy
to use interface. `}<a parentName="p" {...{
          "href": "/news/trailguide2021"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/screenshot_new.png" href="/news/trailguide2021" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/1stApril_logoLena.jpeg" href="/news/pressrelease022021" mdxType="DesktopRightImage" />
      <h3>{`Trailguide inngår samarbeid med satellittselskap`}</h3>
      <h4>{`Vi har allerede fått på plass ny funksjonalitet basert på satellittdata som vil være til stor hjelp for syklister`}</h4>
      <ByLine author="Tonje Sameien" date="2021-04-01" mdxType="ByLine" />
      <br />
      <p>{`Skal samarbeide med satellittselskap, innfører prikk-system for å få bukt med
«strava-syklister» og skal bruke satellittdata og ansiktsgjenkjenningsteknologi
for å identifisere stitroll. `}<a parentName="p" {...{
          "href": "/news/pressrelease022021"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/1stApril_logoLena.jpeg" href="/news/pressrelease022021" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/fatbiking_3.jpg" href="/news/fatbiking" mdxType="DesktopRightImage" />
      <h3>{`Fat Biking now on Trailguide`}</h3>
      <h4>{`Interview med Nina Gässler`}</h4>
      <ByLine author="Peter Seidl" date="2021-01-29" mdxType="ByLine" />
      <br />
      <p>{`"Snø er magi!" sier Nina Gässler, grunnlegger av et av Europas lengste Fatbike-ritt.
Les om fascinasjon av vintersykling og få tips til ditt neste vintereventyr.`}<br />{`
`}<a parentName="p" {...{
          "href": "/news/fatbiking"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/fatbiking_4.jpg" href="/news/fatbiking" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/trailguide_ferry.jpg" href="/news/pressrelease012021" mdxType="DesktopRightImage" />
      <h3>{`Trailguide vokser`}</h3>
      <h4>{`Flere ansatte, ny investor`}</h4>
      <ByLine author="Tonje Sameien" date="2021-01-07" mdxType="ByLine" />
      <br />
      <p>{`Trailguide vokser. Med ny investor, støtte fra Gol Kommune og lånemidler fra
Innovasjon Norge får Trailguide viktig kapital til å utvide teamet, og dermed
flere ressurser til utvikling.`}<br />{`
`}<a parentName="p" {...{
          "href": "/news/pressrelease012021"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/trailguide_ferry.jpg" href="/news/pressrelease012021" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/destinations/hardangerfjord_14.jpg" href="/news/customerexperience" mdxType="DesktopRightImage" />
      <h3>{`Improve your customer experience`}</h3>
      <h4>{`Three tips to get happy customers`}</h4>
      <ByLine author="Peter Seidl" mdxType="ByLine" />
      <br />
      <p>{`Happy customers stay longer, recommend your destination, and they come back!
As a destination offering outdoor activities, you want your customers to be
able to ... `}<br />{`
`}<a parentName="p" {...{
          "href": "/news/customerexperience"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/trailguidephone1.jpg" href="/news/sharingtrails" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/trailguidephone1.jpg" href="/news/sharingtrails" mdxType="DesktopRightImage" />
      <h3>{`Sharing is bad! Or not?`}</h3>
      <h4>{`Discussing the effects on mountain biking`}</h4>
      <ByLine author="Peter Seidl" date="2020-06-12" mdxType="ByLine" />
      <br />
      <p>{`Does sharing information about great trails and beautiful places have a negative effect on mountain biking?
Here is why I think there are many positive effects!
`}<a parentName="p" {...{
          "href": "/news/sharingtrails"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/trailguidephone1.jpg" href="/news/sharingtrails" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/peter_bjorn_gunther.jpg" href="/news/support" mdxType="DesktopRightImage" />
      <h3>{`Trailguide Patreon`}</h3>
      <h4>{`Consider helping us with our digital service bills`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2020-06-07" mdxType="ByLine" />
      <br />
      <p>{`Trailguide is a free bike guide for everyone. We put in a lot of work to
develop and run the service. With the increased traffic we experience there are
also rising costs for the digital services we use to run Trailguide.
`}<a parentName="p" {...{
          "href": "/news/support"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/peter_bjorn_gunther.jpg" href="/news/support" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/StartScreenEuropeNEW.jpg" href="/news/trailguide2020" mdxType="DesktopRightImage" />
      <h3>{`New Trailguide App 2020`}</h3>
      <h4>{`New functions, new map, easier to use`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2020-05-28" mdxType="ByLine" />
      <br />
      <p>{`After a long winter full of work, we finally can release Trailguide 2020. Find lots
of new features that make it even easier to find and share great trails!
`}<a parentName="p" {...{
          "href": "/news/trailguide2020"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/StartScreenEuropeNEW.jpg" href="/news/trailguide2020" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/httlocationios.jpg" href="/news/iphonelocation" mdxType="DesktopRightImage" />
      <h3>{`How to turn on the GPS for iPhones`}</h3>
      <h4>{`Location service for Trailguide in IOS`}</h4>
      <ByLine author="Peter Seidl" date="2019-10-25" mdxType="ByLine" />
      <br />
      <p>{`Here you can find out how you can activate the location services and see your
own location in Trailguide to find and navigate on the best trails.
`}<a parentName="p" {...{
          "href": "/news/iphonelocation"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/httlocationios.jpg" href="/news/iphonelocation" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/localservicesmapclose3d.jpg" href="/news/mapservices" mdxType="DesktopRightImage" />
      <h3>{`Local Services on the map!`}</h3>
      <h4>{`Additional information & better visibility`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2019-09-27" mdxType="ByLine" />
      <br />
      <p>{`Now you can see local services on the map! See all
information along with the point of interest.
`}<a parentName="p" {...{
          "href": "/news/mapservices"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/localservicesmapclose3d.jpg" href="/news/mapservices" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/jerseyfront2.jpg" href="/news/jersey" mdxType="DesktopRightImage" />
      <h3>{`Trailguide jersey`}</h3>
      <h4>{`Pre order opens now!`}</h4>
      <ByLine author="Peter Seidl" date="2019-09-18" mdxType="ByLine" />
      <br />
      <p>{`They are green, they are from Trailguide, they are for biking, they are awesome!
`}<a parentName="p" {...{
          "href": "/news/jersey"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/jerseyfront2.jpg" href="/news/jersey" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/v2-addtohomescreen2.jpg" href="/news/newversion" mdxType="DesktopRightImage" />
      <h3>{`New version of Trailguide`}</h3>
      <h4>{`Smaller, faster, better!`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2019-08-09" mdxType="ByLine" />
      <br />
      <p>{`Trailguide can be installed on your phone, has new functions, is smaller,
faster, and easier to use!
`}<a parentName="p" {...{
          "href": "/news/newversion"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/v2-addtohomescreen2.jpg" href="/news/newversion" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/userawards0619.jpg" href="/news/norronauserawards" mdxType="DesktopRightImage" />
      <h3>{`User awards by Norrøna`}</h3>
      <h4>{`Trailguide Community`}</h4>
      <ByLine author="Peter Seidl" date="2019-07-10" mdxType="ByLine" />
      <br />
      <p>{`Less driving and flying because of more, and easy accessible information?
Share the local trails, and win clothing and equipment from Norrøna.
`}<a parentName="p" {...{
          "href": "/news/norronauserawards"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/userawards0619.jpg" href="/news/norronauserawards" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="webapp/screenshot_money.jpg" href="/news/adsforlocalservices" mdxType="DesktopRightImage" />
      <h3>{`Ads for local services`}</h3>
      <h4>{`Trailguide Professional`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2019-04-14" mdxType="ByLine" />
      <br />
      <p>{`Are you a bike shop, guide, restaurant, accomodation, or offer any other bike
and tourism related services? Promote your local service on Trailguide and get
more customers!
`}<a parentName="p" {...{
          "href": "/news/adsforlocalservices"
        }}>{`read more...`}</a></p>
      <PhoneImage src="webapp/screenshot_money.jpg" href="/news/adsforlocalservices" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/evocuserawards2018_1.jpg" href="/news/evocuserawards" mdxType="DesktopRightImage" />
      <h3>{`EVOC userawards`}</h3>
      <h4>{`Trailguide Community`}</h4>
      <ByLine author="Peter Seidl" date="2018-10-28" mdxType="ByLine" />
      <br />
      <p>{`Did you ride and track some nice singletracks this season? Upload your tracks
to Trailguide and you will participate in the drawing of the EVOC User Awards
on November 15th.
`}<a parentName="p" {...{
          "href": "/news/evocuserawards"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/evocuserawards2018_1.jpg" href="/news/evocuserawards" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/bestofcuenca2.jpg" href="/news/newspots2018part2" mdxType="DesktopRightImage" />
      <h3>{`Best of Trailguide`}</h3>
      <h4>{`The best new trails and destinations PART 2`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2018-09-11" mdxType="ByLine" />
      <br />
      <p>{`So many great trails were already uploaded by our user community that we have
to show you the best new riding spots. In PART 2 you find Ligurian goods, the
French Côte d'Azur, riding at the foothills of Mont-Blanc, and hot Spanish
Enduro trails.
`}<a parentName="p" {...{
          "href": "/news/newspots2018part2"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/bestofcuenca2.jpg" href="/news/newspots2018part2" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/logouserawards3.jpg" href="/news/userawards181" mdxType="DesktopRightImage" />
      <h3>{`User Awards`}</h3>
      <h4>{`Trailguide community`}</h4>
      <ByLine author="Peter Seidl" date="2018-08-31" mdxType="ByLine" />
      <br />
      <p>{`So many trails were uploaded by many users in the first half of the season
that we already want to reward the contributors. With our partners the Aletsch
Arena and Hotel Alpenblick, we send you to a great bike weekend.
`}<a parentName="p" {...{
          "href": "/news/userawards181"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/logouserawards3.jpg" href="/news/userawards181" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/kronplatztt.jpg" href="/news/winticketsttkronplatz18" mdxType="DesktopRightImage" />
      <h3>{`Win tickets for the Trail Trophy Kronplatz`}</h3>
      <h4>{`The last race of the Trail Trophy series.`}</h4>
      <ByLine author="Peter Seidl" date="2018-08-29" mdxType="ByLine" />
      <br />
      <p>{`Slowly autumn is coming and the 2018 Trail Trophy race series comes to an end.
Take the chance and win two tickets to the last Enduro race at the Kronplatz,
Italy.
`}<a parentName="p" {...{
          "href": "/news/winticketsttkronplatz18"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/kronplatztt.jpg" href="/news/winticketsttkronplatz18" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/uploadtrails_3.jpg" href="/news/uploadtrails" mdxType="DesktopRightImage" />
      <h3>{`Upload your tracks`}</h3>
      <h4>{`Share your trails and find thousands of others!`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2018-08-14" mdxType="ByLine" />
      <br />
      <p>{`Were you ever glad about a tip for a great MTB singletrack? On Trailguide you
can find and share the best singletracks. Upload your trails and win bike
trips and equipment!
`}<a parentName="p" {...{
          "href": "/news/uploadtrails"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/uploadtrails_3.jpg" href="/news/uploadtrails" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="destinations/reschen_1.jpg" href="/news/wintickets3laenderenduro18" mdxType="DesktopRightImage" />
      <h3>{`Win tickets for the 3 Länder Enduro Race`}</h3>
      <h4>{`Booked out in minutes you now can win two tickets`}</h4>
      <ByLine author="Peter Seidl" date="2018-08-09" mdxType="ByLine" />
      <br />
      <p>{`The resort at the border of Austria and Italy is truly a gem for all enduro
fans. The lifts open up a wide area around the 1500m high Reschenpass with
it’s two beautiful lakes.
`}<a parentName="p" {...{
          "href": "/news/wintickets3laenderenduro18"
        }}>{`read more...`}</a></p>
      <PhoneImage src="destinations/reschen_1.jpg" href="/news/wintickets3laenderenduro18" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/heightprofile_1.jpg" href="/news/heightprofile" mdxType="DesktopRightImage" />
      <h3>{`Height Profiles`}</h3>
      <h4>{`Long desired: now all tracks include height profiles`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2018-08-01" mdxType="ByLine" />
      <br />
      <p>{`As the name already says, mountain biking is a mountain sport. The most
obvious characteristic of a mountain is the elevation. Now you find all the
numbers about climbs and descents on Trailguide.
`}<a parentName="p" {...{
          "href": "/news/heightprofile"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/heightprofile_1.jpg" href="/news/heightprofile" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="destinations/flimslaaxvorab.jpg" href="/news/winticketsflimslaax18" mdxType="DesktopRightImage" />
      <h3>{`Win tickets for the Trail Trophy Flims Laax`}</h3>
      <h4>{`Enjoy the best trails in three days of Racing`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2018-07-11" mdxType="ByLine" />
      <br />
      <p>{`Flims Laax in Graubünden, Switzerland offers some of the most versatile
terrain to ride your mountain bike on. In three days of racing you can
discover the best singletracks of the area in a relaxed atmosphere.
`}<a parentName="p" {...{
          "href": "/news/winticketsflimslaax18"
        }}>{`read more...`}</a></p>
      <PhoneImage src="destinations/flimslaaxvorab.jpg" href="/news/winticketsflimslaax18" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/bestof2018valldal3.jpg" href="/news/newspots2018part1" mdxType="DesktopRightImage" />
      <h3>{`Best of Trailguide`}</h3>
      <h4>{`See the best new trails and destinations PART 1`}</h4>
      <ByLine author="Peter Seidl" date="2018-07-04" mdxType="ByLine" />
      <br />
      <p>{`So many great trails were already uploaded by our user community, that we have
to show you the best new spots for biking already mid season. In PART 1 you
find Norwegian fjords, Romanian forests and Greek coast trails.
`}<a parentName="p" {...{
          "href": "/news/newspots2018part1"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/bestof2018valldal3.jpg" href="/news/newspots2018part1" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="nesbyen_enduro_2018/nesbyen2018-25.jpg" href="/news/nesbyenenduro2018" mdxType="DesktopRightImage" />
      <h3>{`Nesbyen Enduro 2018`}</h3>
      <h4>{`Two thousand vertical meters of dry and dusty trails`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2018-05-28" mdxType="ByLine" />
      <br />
      <p>{`This weekend the first race of the Norwegien 80/20 enduro series was held in
Nesbyen, Hallingdal in Norway on perfect dry and dusty trails in a fantastic
weather. The event was also an EWS qualifier, so you could find happy riders
from Norway, Sweden, Australia, UK, Denmark and more.
`}<a parentName="p" {...{
          "href": "/news/nesbyenenduro2018"
        }}>{`read more...`}</a></p>
      <PhoneImage src="nesbyen_enduro_2018/nesbyen2018-25.jpg" href="/news/nesbyenenduro2018" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/pluginexample.jpg" href="/news/pluginexamples" mdxType="DesktopRightImage" />
      <h3>{`Easy trail maps with Trailguide`}</h3>
      <h4>{`Present your destination, trails or event.`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2018-05-15" mdxType="ByLine" />
      <br />
      <p>{`Configure and embedd a trail map on your website, in an article or even in
your app. Show destination or event specific content with the easy trail maps
of Trailguide.
`}<a parentName="p" {...{
          "href": "/news/pluginexamples"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/pluginexample.jpg" href="/news/pluginexamples" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/ttlatsch18_5.jpg" href="/news/winthreeticketsttlatsch18" mdxType="DesktopRightImage" />
      <h3>{`Win 3 tickets for the Trail Trophy in Latsch`}</h3>
      <h4>{`Latsch in Südtirol, Italy calls for the next enduro race and you can win three tickets to enter.`}</h4>
      <ByLine author="Peter Seidl" date="2018-05-09" mdxType="ByLine" />
      <br />
      <p>{`The Vinschgau valley is one of the best areas in the Alps for mountain biking.
From the 25th-27th May, the next race of the Trail Trophy Enduro Series takes
place there and you can win three tickets to enter.
`}<a parentName="p" {...{
          "href": "/news/winthreeticketsttlatsch18"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/ttlatsch18_5.jpg" href="/news/winthreeticketsttlatsch18" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/ttstandreasbergscreenshot.jpg" href="/news/ttstandreasberg18" mdxType="DesktopRightImage" />
      <h3>{`Trail maps for enduro races`}</h3>
      <h4>{`Interactive race maps for the Trail Trophy`}</h4>
      <ByLine author="Peter Seidl" date="2018-05-07" mdxType="ByLine" />
      <br />
      <p>{`Interactive trail maps from Trailguide are not only available for MTB
destinations, bike clubs ore festivals. Now you can also show your enduro race
on Trailguide.
`}<a parentName="p" {...{
          "href": "/news/ttstandreasberg18"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/ttstandreasbergscreenshot.jpg" href="/news/ttstandreasberg18" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/topdest1.jpg" href="/news/topdestinations2017" mdxType="DesktopRightImage" />
      <h3>{`Top Destinations`}</h3>
      <h4>{`Check out our 10 favourite biking locations for 2018`}</h4>
      <ByLine author="Peter Seidl" date="2018-03-27" mdxType="ByLine" />
      <br />
      <p>{`We show you the destinations we are still dreaming of. Of great trails and of
an awesome nature. Places where we would love to go back immediately.
`}<a parentName="p" {...{
          "href": "/news/topdestinations2017"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/topdest1.jpg" href="/news/topdestinations2017" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/toptrail1.jpg" href="/news/toptrails2017" mdxType="DesktopRightImage" />
      <h3>{`Top Trails`}</h3>
      <h4>{`These are our 10 favourite mtb singletracks for 2018`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2018-03-16" mdxType="ByLine" />
      <br />
      <p>{`Long or short, close or far away. Doesn't matter if you finish the trail with
a big smile on your face.
`}<a parentName="p" {...{
          "href": "/news/toptrails2017"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/toptrail1.jpg" href="/news/toptrails2017" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/bestpic1.jpg" href="/news/bestpictures2017" mdxType="DesktopRightImage" />
      <h3>{`Best Pictures`}</h3>
      <h4>{`Gallery & calendar`}</h4>
      <ByLine author="Peter Seidl" date="2018-03-08" mdxType="ByLine" />
      <br />
      <p>{`The best pictures of 2017 plus the trails they were shot on. Also win five
calendars printed in high quality.
`}<a parentName="p" {...{
          "href": "/news/bestpictures2017"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/bestpic1.jpg" href="/news/bestpictures2017" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/userawards1.jpg" href="/news/userawards2017" mdxType="DesktopRightImage" />
      <h3>{`User Awards`}</h3>
      <h4>{`Trailguide community`}</h4>
      <ByLine author="Peter Seidl" date="2018-03-02" mdxType="ByLine" />
      <br />
      <p>{`Over 1.000 trails were added by the Trailguide community, pictures uploaded
and trails reviewed. We want to thank you for contributing and making
Trailguide a great and very useful guide for everyone. Here we reward the most
active users.
`}<a parentName="p" {...{
          "href": "/news/userawards2017"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/userawards1.jpg" href="/news/userawards2017" mdxType="PhoneImage" />
    </BlogEntry>
    <BlogEntry mdxType="BlogEntry">
      <DesktopRightImage src="/news/software1.jpg" href="/news/softwareupdate2017" mdxType="DesktopRightImage" />
      <h3>{`Software Update`}</h3>
      <h4>{`New functions and cool technology`}</h4>
      <ByLine author="Bjørn Jarle Kvande" date="2018-02-19" mdxType="ByLine" />
      <br />
      <p>{`What functions were developed lately in Trailguide and what the heck is a web
app?
`}<a parentName="p" {...{
          "href": "/news/softwareupdate2017"
        }}>{`read more...`}</a></p>
      <PhoneImage src="/news/software1.jpg" href="/news/softwareupdate2017" mdxType="PhoneImage" />
    </BlogEntry>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      